export function SignUpPage() {
	return (
		<div>
			<h1>Зарегистрируйтесь</h1>
			<form action="">
				<input type="text" placeholder={'Введите текст'} />
			</form>
		</div>
	);
}
