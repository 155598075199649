import './Header.scss';
import classes from './Header.cn';
import { Col, Container, Row } from '../../shared/ui';
import { Avatar, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react';

export function Header() {
	const styles = classes();

	return (
		<>
			<header className={styles.block}>
				<Container>
					<Row>
						<Col className="flex justify-between items-center">
							<p className="font-bold text-inherit text-blue-500">Dentify | Logo</p>
							<Dropdown placement="bottom-end">
								<DropdownTrigger>
									<Avatar
										isBordered
										as="button"
										className="transition-transform"
										color="primary"
										name="Jason Hughes"
										size="sm"
										imgProps={{ referrerPolicy: 'no-referrer' }}
										src="https://i.pravatar.cc/150?u=a042581f4e29026704d"
									/>
								</DropdownTrigger>
								<DropdownMenu aria-label="Profile Actions" variant="flat">
									<DropdownItem key="settings">My Settings</DropdownItem>
									<DropdownItem key="team_settings">Team Settings</DropdownItem>
									<DropdownItem key="analytics">Analytics</DropdownItem>
									<DropdownItem key="system">System</DropdownItem>
									<DropdownItem key="configurations">Configurations</DropdownItem>
									<DropdownItem key="help_and_feedback">Help & Feedback</DropdownItem>
									<DropdownItem key="logout" color="danger" className="text-danger">
										Log Out
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
						</Col>
					</Row>
				</Container>
			</header>
		</>
	);
}
