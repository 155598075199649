import { Outlet, Navigate } from 'react-router-dom';
import { ROUTER_PATHS } from '../shared/constants/routes';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

export default function RouterProtected() {
	const user = useSelector((state: RootState) => state.user);

	return user.is_loggedIn ? <Outlet /> : <Navigate to={ROUTER_PATHS.LOGIN} />;
}
