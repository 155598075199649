import { Link, Navigate } from 'react-router-dom';
import { ROUTER_PATHS } from '../../shared/constants/routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

export function LogInPage() {
	const user = useSelector((state: RootState) => state.user);

	if (user.is_loggedIn) {
		return <Navigate to={ROUTER_PATHS.HOME} />;
	}

	return (
		<div>
			<h2>Войдите</h2>
			<form action="">
				<input type="text" />
			</form>
			<Link to={ROUTER_PATHS.SIGN_UP}>Или зарегистрируйтесь (редирект на главную если user есть)</Link>
		</div>
	);
}
