import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { ROUTER_PATHS } from '../shared/constants/routes';
import Layout from '../Layout';
import { AboutPage, BlogDetailPage, BlogPage, HomePage, NotFoundPage, LogInPage, SignUpPage } from '../pages';
import RouterProtected from './RouterProtected';

const router = createBrowserRouter(
	createRoutesFromElements(
		<>
			<Route element={<RouterProtected />}>
				<Route path={ROUTER_PATHS.HOME} element={<Layout />}>
					<Route index element={<HomePage />} />
					<Route path={ROUTER_PATHS.BLOG} element={<BlogPage />} />
					<Route path={ROUTER_PATHS.BLOG_DETAIL} element={<BlogDetailPage />} />
					<Route path={ROUTER_PATHS.ABOUT} element={<AboutPage />} />
					<Route path={ROUTER_PATHS.NOT_FOUND} element={<NotFoundPage />} />
				</Route>
			</Route>
			<Route path={ROUTER_PATHS.LOGIN} element={<LogInPage />} />
			<Route path={ROUTER_PATHS.SIGN_UP} element={<SignUpPage />} />
		</>
	)
);

export function RouterProviderApp() {
	return <RouterProvider router={router} />;
}
