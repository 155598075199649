import IEntitiesExample from './EntitiesExample.types';
import './EntitiesExample.scss';
import classes from './EntitiesExample.cn';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Input } from '@nextui-org/react';

export function EntitiesExample(props: IEntitiesExample) {
	const { firstName } = useSelector((state: RootState) => state.user);
	const { cn } = props;
	const styles = classes({ ...cn });

	return (
		<div className={styles.block}>
			Store {firstName}
			<div className="flex w-full flex-wrap md:flex-nowrap gap-4 mb-10">
				<Input type="email" label="Email" />
				<Input type="password" label="Password" />
			</div>
		</div>
	);
}
