import React from 'react';
import ReactDOM from 'react-dom/client';
import './main.scss';
import App from './App';
import { store } from './store/store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
import { NextUIProvider } from '@nextui-org/react';

root.render(
	<Provider store={store}>
		<NextUIProvider>
			<App />
		</NextUIProvider>
	</Provider>
);
